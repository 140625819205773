/**********************************************/
// Capa de Servicios para el modulo de Hoteles.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getRecordSlu(slu) {
    try {
      let response = await axios.get(`${this.#baseUrl}/slu/houses/${slu}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/houses");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/houses");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList(active=false, city='', municipality='') {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/houses", {
        params: {
          active: active,
          city: city,
          municipality: municipality
        }
      });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/houses/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    await this.reload();
    try {
      return axios.post(this.#baseUrl + "/houses", {
        name: formData.name,
        description: formData.description,
        description_english: formData.description_english,
        establishment_type: formData.establishment_type,
        email: formData.email,
        address: formData.address,
        phone: formData.phone,
        web_page: formData.web_page,
        map_lat: formData.map_lat,
        map_lng: formData.map_lng,
        facebook_profile: formData.facebook_profile,
        instagram_profile: formData.instagram_profile,
        servicehours: formData.servicehours,
        active: formData.active,
        typeEntity: formData.typeEntity,
        coparmex: formData.coparmex,
        state: formData.state,
        comment: formData.comment,
        comment_ceetsb: formData.comment_ceetsb,
        comment_coparmex: formData.comment_coparmex,
        comment_coepris: formData.comment_coepris,
        state_ceetsb: formData.state_ceetsb,
        state_coparmex: formData.state_coparmex,
        state_coepris: formData.state_coepris,
        videos: formData.videos,
        excellence: formData.excellence,
        repsabi: formData.repsabi,
        province: formData.province,
        municipality: formData.municipality,
        trial: formData.trial,
      }, {
          headers: this.header
        });
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      return axios.put(`${this.#baseUrl}/houses/${formData.id}`, {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        description_english: formData.description_english,
        establishment_type: formData.establishment_type,
        email: formData.email,
        address: formData.address,
        phone: formData.phone,
        web_page: formData.web_page,
        map_lat: formData.map_lat,
        map_lng: formData.map_lng,
        facebook_profile: formData.facebook_profile,
        instagram_profile: formData.instagram_profile,
        servicehours: formData.servicehours,
        active: formData.active,
        typeEntity: formData.typeEntity,
        coparmex: formData.coparmex,
        state: formData.state,
        comment: formData.comment,
        comment_ceetsb: formData.comment_ceetsb,
        comment_coparmex: formData.comment_coparmex,
        comment_coepris: formData.comment_coepris,
        state_ceetsb: formData.state_ceetsb,
        state_coparmex: formData.state_coparmex,
        state_coepris: formData.state_coepris,
        videos: formData.videos,
        excellence: formData.excellence,
        repsabi: formData.repsabi,
        province: formData.province,
        municipality: formData.municipality,
        trial: formData.trial,
      },
      {
        headers: this.header
      });
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/houses/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(house_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("house_id", house_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/houses",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(house_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("house_id", house_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/houses",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/houses?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  async updateCertification(data){
    return axios.put(`${this.#baseUrl}/update-certification/houses`, data,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                    }
                }
            )
  }

  async addCoeprisQR(id, file, expire_date_qr, coepris_qr_link){
    const form = new FormData();
    form.append("id", id);
    form.append("file", file);
    form.append("expire_date_qr", expire_date_qr);
    form.append("coepris_qr_link", coepris_qr_link);
    return axios.post(`${this.#baseUrl}/qr-coepris/houses`, form,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                    }
                }
            );
  }

  async deleteCoeprisQR(id){
    return axios.delete(`${this.#baseUrl}/delete-qr-coepris/houses/${id}`,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                    }
                }
            );
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
